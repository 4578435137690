/* RESET CSS */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

button {
  border: none;
  outline: none;
}

input[type="checkbox"] {
  accent-color: var(--primary_purple);
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
  padding-left: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

/* APP GENERAL */

@font-face {
  font-family: "SourceSans";
  src: local("SourceSans"),
    url("../../Assets/Fonts/SourceSans3-VariableFont_wght.ttf") format("truetype");
}

/* @font-face {
  font-family: "Catamaran";
  src: local("Catamaran"),
    url("../../Assets/Fonts//Catamaran-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "InterTightItalic";
  src: local("InterTightItalic"),
    url("../../Assets/Fonts//InterTight-Italic.ttf") format("truetype");
} */

.app_container {
  overflow-x: hidden;
  background-color: var(--background_gray);
}

.app_main_content {
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin-left: 15%;
  width: 100%;
}

.app_container.hideFullSidebar .app_main_content {
  margin-left: 5%;
  margin-right: 5%;
}

.app_main_content > div {
  margin-top: calc(20px + var(--header_height));
  padding-left: 2%;
  padding-bottom: 80px;
}

/* LOADING */
.loading_container {
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.loading_container > span {
  z-index: 25;
}
.loading_container > p {
  color: #fff;
  z-index: 25;
  font-size: 30px;
}

.loading_container .overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.align_center {
  text-align: center;
}

.align_left {
  text-align: left;
}

.text_bold {
  font-weight: bold;
}

.error_color {
  color: var(--fov_return_dark);
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.padding_5 {
  padding: 5%;
}

.padding_top_10 {
  padding-top: 10%;
}

.padding_2 {
  padding: 2%;
}

.bold_text {
  font-weight: bold;
}

/* flex */
.flex_around {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.flex_2 {
  flex: 2;
}

.button {
  outline: none;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button.btn_primary {
  background-color: var(--primary_purple);
}

.button.btn_danger {
  background-color: var(--fov_return_dark);
}

.button.btn_disable {
  background-color: var(--border);
  color: var(--sup_not_submit_dark);
}

.display_none {
  display: none;
}

.display_block {
  display: block !important;
}

.no_margin {
  margin: 0;
}
.no_padding {
  padding: 0 !important;
}

.require_signal {
  color: rgb(250, 25, 25) !important;
}

.see_more {
  width: 84%;
  text-align: right;
}

.notFound h1 {
  font-size: 40px;
  font-weight: 700;
}

/* MOBILE DISPLAY */
@media (max-width: 767px) {
  .app_main_content {
    margin-left: -3px !important;
  }
  .hide_mobile {
    display: none !important;
  }
  .mobile_display_block {
    display: inline-block !important;
  }
}
/* TABLET AND MOBILE */
@media (max-width: 991px) {
}

/* TABLET */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .app_main_content {
    margin-left: 0;
  }
  .app_main_content > div {
    padding: 0;
  }
}

/* SMALL */
@media (min-width: 992px) and (max-width: 1399px) {
  .col-lg-11 {
    width: 93%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
