/* Control Sidebar */
.sidebar_container {
  width: 15%;
  /* background: rgb(77, 40, 210);
  background: linear-gradient(
    180deg,
    rgb(91, 40, 210, 1) 50%,
    rgb(0, 153, 255, 1) 100%
  ); */
  background-color: #424242;
  padding: 2% 0 0 0;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sidebar_container.hideFull {
  width: 5%;
  align-items: center;
}

.sidebar_header,
.sidebar_body,
.sidebar_footer {
  margin-left: 5%;
  color: var(--text_white);
  letter-spacing: 1px;
}

/* Sidebar header */
.sidebar_header {
  display: flex;
  align-items: center;
}

.sidebar_heading_infor h6 {
  font-weight: bold;
  font-size: 18px;
}

.sidebar_heading_infor p {
  font-size: 11px;
}

.sidebar_heading_avatar_container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #80a3ee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar_heading_avatar_container img {
  height: 90%;
  width: 90%;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar_heading_menu_icon_container {
  font-size: 20px;
  background-color: var(--primary_purple);
  color: #fff;
  display: flex;
  height: 40px !important;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  top: -5%;
  left: 92%;
  cursor: pointer;
}

/* HIDE side bar */
.sidebar_container.sidebar_container.hideFull
  .sidebar_body
  .sidebar_body_list
  .sidebar_body_item
  p,
.sidebar_container.sidebar_container.hideFull
  .sidebar_footer
  .sidebar_footer_item
  span,
.sidebar_container.sidebar_container.hideFull
  .sidebar_header
  .sidebar_heading_infor
  h6,
.sidebar_container.sidebar_container.hideFull
  .sidebar_header
  .sidebar_heading_infor
  p {
  display: none;
}

.sidebar_container.sidebar_container.hideFull
  .sidebar_header
  .sidebar_heading_avatar_container {
  margin: 0;
}
/* Sidebar body */
.sidebar_body {
  margin-top: 10%;
}

.sidebar_body_item {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  color: #fff;
}

.sidebar_body_item:hover {
  color: inherit;
}

.sidebar_body_item.active {
  /*  
  background-color: #ffffff;
  color: var(--text_general);
  */
  background-color: var(--primary_purple);
  padding: 15px;
  border-radius: 5px;
  font-weight: bold;
  transform: translateX(10px);
  box-shadow: rgba(0, 0, 0, 0.3) 2.4px 2.4px 3.2px;
  color: #fff;
}

.sidebar_body_item:hover {
  cursor: pointer;
}

.sidebar_body_item_icon {
  margin-right: 10px;
}

.sidebar_body_item p {
  position: relative;
  top: 1px;
  width: 100%;
  height: 100%;
}

/* hide side bar */
.sidebar_container.sidebar_container.hideFull .sidebar_body .sidebar_body_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* FOOTER */
.sidebar_footer {
  margin-top: auto;
  cursor: pointer;
}

.sidebar_footer_item {
  margin-bottom: 30px;
}

/* RESPONSIVE */
/* MOBILE DISPLAY */
@media (max-width: 767px) {
  .sidebar_heading_menu_icon_container,
  .sidebar_container {
    display: none;
  }
  .sidebar_container {
    width: 90%;
    padding-top: 25%;
    z-index: 2;
  }
}

/* TABLET */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar_heading_menu_icon_container,
  .sidebar_container {
    display: none;
  }
  .sidebar_container {
    width: 90%;
    padding-top: 25%;
    z-index: 2;
  }
  .sidebar_container {
    width: 50%;
    padding-top: 10%;
    z-index: 2;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1399px) {
  .sidebar_container  {
    z-index: 3;
  }
  .sidebar_header {
    flex-direction: column !important;
    text-align: center;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
