.modal_container {
  position: absolute;
  content: "";
  right: 0;
  top: 120%;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
  z-index: 8;
}

.modal_heading {
  padding: 14px;
  font-size: 18px;
  font-weight: 600;
  color: #747373;
}

/* Search */
.language_modal_container,
.user_profile_modal_container,
.notification_modal_container,
.search_modal_container {
  position: fixed;
  top: 5%;
  right: 10%;
  width: 20%;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid var(--border);
  z-index: 10;
}

.modal_search_feed {
  width: 100% !important;
  margin-bottom: 0;
}

.search_feed_icon {
  margin-right: 20px;
}

.modal_search_input {
  margin-left: 20px;
  margin-bottom: 5px;
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--border);
  padding: 5px;
  width: 90%;
}

.search_feed_name_item {
  font-size: 13px;
  color: var(--text_general);
}

/* user */
.user_profile_modal_container {
  right: 5%;
}

/* modal footer */
.modal_footer {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--primary);
  cursor: pointer;
}

.modal_footer:hover {
  opacity: 0.8;
}

.modal_reason_container {
  margin: 15px 0;
}

.modal_reason_container label {
  font-size: 14px;
  margin-bottom: 4px;
}

.modal_reason_container input {
  width: 100%;
  outline: none;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 5px 5px;
  height: 80px;
  overflow-x: scroll;
  flex-wrap: wrap;
}

.modal_overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

.modal_small_overlay {
  position: fixed;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: transparent;
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 4;
  padding: 0;
  width: 400px;
  background-color: #fff;
  content: "";
  overflow: hidden;
  z-index: 9;
}

.modal_container button {
  margin-left: 10px;
}

.modal_container .general_modal_heading {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--text_gray);
  padding: 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--border);
  margin-bottom: 20px;
}

.general_modal_heading_icon {
  position: absolute;
  content: "";
  top: 5%;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.general_modal_heading_icon:hover {
  color: var(--primary_purple);
}

.general_modal_body {
  margin-bottom: 20px;
  padding: 20px;
}
.general_modal_body p {
  text-align: center;
  font-weight: 500;
}

.general_modal_footer {
  background-color: var(--background_gray);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.general_modal_footer button {
  width: 45%;
  font-weight: 500;
  margin: 0;
}
.general_modal_footer button:nth-child(1) {
  background-color: #fff;
  border: 1px solid var(--border);
}

.modal_error {
  border: 1px solid red !important;
}

.modal_container.small_modal_container {
  position: fixed;
  top: 14%;
  left: 85%;
}

/* LANGUAGE */
.language_modal_container {
  right: 14%;
  width: 10%;
  padding: 10px;
}

.language_modal_container p {
  margin: 5px 0;
}

.language_modal_container p:hover {
  padding: 4px 10px;
  background-color: var(--background_gray);
  cursor: pointer;
}

/* RESPONSIVE */
/* MOBILE DISPLAY */
@media (max-width: 767px) {
  .modal_container {
    width: 350px;
  }
  .general_modal_body {
    margin-bottom: 0;
    padding: 10px;
  }
  .language_modal_container,
  .user_profile_modal_container,
  .notification_modal_container,
  .search_modal_container {
    top: 8%;
    right: 0%;
    width: 100%;
    border-radius: 0;
    border: none;
  }
  .modal_small_overlay {
    top: 6%;
  }
  .modal_fuji_logo_container {
    width: 50px;
    height: 50px;
  }
}

/* TABLET */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .language_modal_container,
  .user_profile_modal_container,
  .notification_modal_container,
  .search_modal_container {
    width: 50%;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1399px) {
  .language_modal_container,
  .user_profile_modal_container,
  .notification_modal_container,
  .search_modal_container {
    width: 30%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
