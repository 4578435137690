:root { --text_general: rgb(77, 77, 77);
  --text_gray: #7c7c7c;
  --text_white: #fff;
  --text_primary: #0d92f8;
  --text_submit: #17d38d;
  --primary: #087cf0;
  --primary_dark_medium: #0018f5;
  --primary_medium: #61acf8;
  --light_primary: #b0d4f8;
  --dark_primary: #032c55;
  --background_gray: #f0f0f0;
  --status_6_approve: #bad4e9;
  --status_4_submit: #d5e8d4;
 /*  --primary_purple: #046bd1; */
  --primary_purple: rgb(77, 40, 210);

  --fov_return_dark: #f54444;
  --fov_return_medium: #f78787;
  --fov_return_light: #f4c1c1;

  --sup_not_submit_dark: #4c4c4c;
  --sup_not_submit_medium: #adadad;
  --sup_not_submit_light: #cccccc;

  --sup_ack_dark: #5f00b2;
  --sup_ack_medium: #bd7df5;
  --sup_ack_light: #d9b5f7;

  --sup_return_dark: #fd7404;
  --sup_return_medium: #f7b150;
  --sup_return_light: #fbddb3;

  --fov_checking_dark: #945a03;
  --fov_checking_light: #eedabb;

  --fov_approved_dark: #29a806;
  --fov_approved_light: #b2f79d;

  --border: #dddddd;
  --icon: #818181;
  --header_height: 60px;
  --purple: #c47aff;
  --blue_purple_gradient: #7978ff;
  --blue_purple_gradient--light: #4649ff;
  --green: #50c878;
  --beige: #ffe7a0;
  --pink: #e0b0ff;

  --chart_01: #6794dc;
  --chart_02: #6771dc;
  --chart_03: #8067dc;
  --chart_04: #a367dc;
  --chart_05: #c767dc;
  --chart_06: #dc67ce;
  --chart_07: #dc67ab;
  --chart_07: #dc6788;
  --chart_09: #dc67ce;

}
