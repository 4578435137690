.navigation_container {
  background-color: rgb(255, 255, 255);
  padding: 15px;
  height: var(--header_height);
  margin-bottom: 15px;
  display: flex;
  justify-content: right;
  align-items: center;
  position: fixed;
  left: 15%;
  right: -1%;
  z-index: 2;
}

.navigation_current_page {
  margin-right: auto;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  color: var(--dark_primary);
  text-transform: uppercase;
}

/* Search zone */
.language_container,
.search_container {
  padding: 8px;
  border-radius: 50%;
  background-color: var(--background_gray);
  position: relative;
  margin-left: 20px;
}

.search_container input {
  outline: none;
  border: none;
}

.search_container input::placeholder {
  color: #cdcdcd;
  font-size: 14px;
}

.search_icon {
  color: var(--icon);
  font-size: 20px;
  cursor: pointer;
}

.language_container,
.user_container,
.bell_container {
  margin-left: 20px;
  padding: 8px;
}

/* user */

.user_container {
  margin-right: 5%;
  position: relative;
  z-index: 1;
}
.user_container:hover,
.modal_content:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal_item > div {
  text-align: left;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.user_profile_logOut_icon {
  color: var(--icon);
  margin-right: 5px;
}

/* Bell container */

.bell_container {
  border-radius: 50%;
  background-color: var(--background_gray);
  position: relative;
}

.user_icon,
.bell_icon {
  color: var(--icon);
  font-size: 20px;
  cursor: pointer;
}

.nof_item {
  position: relative;
}

.nof_item span {
  position: absolute;
  content: "";
  bottom: 10px;
  left: 15px;
  width: 18px;
  font-size: 12px;
  height: 18px;
  color: var(--text_white);
  font-weight: 500;
  background-color: rgb(255, 23, 23);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* NOT */
.modal_nof_content {
  display: block !important;
}

.modal_nof_content > div {
  margin-bottom: 3px;
}
.modal_nof_heading {
  color: var(--primary_purple);
  font-size: 14px;
  font-weight: 700;
}

.modal_nof_infor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_nof_desc span {
  font-weight: 500;
}

.modal_nof_time {
  font-weight: 600;
  color: #757575;
}

.modal_nof_order {
  font-size: 20px;
  font-weight: 600;
  flex: 0.2;
}

.modal_nof_content {
  flex: 2;
} 

/* HIDE FULL */
.navigation_container.hideFull {
  left: 5%;
}

.navigation_container .navigation_menu {
  display: none;
}

/* RESPONSIVE */
/* MOBILE DISPLAY */
@media (max-width: 767px) {
}

/* TABLET AND MOBILE */
@media (max-width: 991px) {
  .navigation_container {
    left: 0;
  }
  .navigation_container .navigation_menu {
    display: block;
  }
  .user_container,
  .bell_container,
  .search_container {
    position: initial;
  }
}

/* TABLET */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1399px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
